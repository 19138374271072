import { log } from '@basaldev/blocks-frontend-sdk';
import * as Sentry from '@sentry/react';

export class SentryLogger extends log.FrontendLogger {
  protected formatLogMessage(
    meta: log.LoggerMeta,
    param: unknown[],
    level: log.LogLevel
  ): [string, ...unknown[]] {
    const formattedMessage = super.formatLogMessage(meta, param, level);

    const sentryLevel = this.mapLogLevelToSentryLevel(level);

    Sentry.captureMessage(
      formattedMessage.filter((value) => typeof value === 'string').join(' '),
      {
        extra: {
          param,
        },
        level: sentryLevel,
      }
    );

    return formattedMessage;
  }

  private mapLogLevelToSentryLevel(level: log.LogLevel): Sentry.SeverityLevel {
    switch (level) {
      case 'debug':
        return 'debug';
      case 'info':
        return 'info';
      case 'warn':
        return 'warning';
      case 'error':
        return 'error';
      default:
        return 'log';
    }
  }
}
